import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import BGImg from 'gatsby-background-image'

import './index.css'

import Layout from '../components/layout'
import SEO from '../components/seo'

const DankePage = () => {
  const data = useStaticQuery(graphql`
    query {
      brief: file(relativePath: { eq: "kontakt/brief.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title='CSU Neutraubling' />
      <BGImg
        className='jumbotron jumbotron-fluid'
        fluid={data.brief.childImageSharp.fluid}>
        <div className='container'>
          <h1
            className='display-4 text-light'
            style={{ textShadow: '0 0 10px black', fontWeight: 'normal' }}>
            Kontakt
          </h1>
        </div>
      </BGImg>
      <div className='container my-5'>
        <h2>Vielen Dank für Ihre Nachricht!</h2>
        <p>
          Wir werden uns so schnell wie möglich mit Ihnen in Verbindung setzen.
        </p>
      </div>
    </Layout>
  )
}

export default DankePage
